import { useState, useEffect } from "react";

import './Settings.css';

import Comp_Topic from "components/Topic";

export default function Page_Settings(props){

    return(
        <div className="show_page">
            <Comp_Topic namePage="Configuração" />
        </div>
    )
}