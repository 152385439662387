import React, { useState, useEffect } from "react";

import './Schedule.css';

import { GetDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";

import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

export default function PopUP_Schedule(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ id, setId ] = useState(0);
    const [ date, setDate ] = useState('');
    const [ startHour, setStartHour ] = useState('');
    const [ endHour, setEndHour ] = useState('');
    const [ idPatient, setIdPatient ] = useState(0);
    const [ title, setTitle ] = useState('');
    const [ healthInsurance, setHealthInsurance ] = useState('');
    const [ phone_1, setPhone_1 ] = useState('');
    const [ phone_2, setPhone_2 ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ information, setInformation ] = useState('');
    const [ listPatient, setListPatient ] = useState([]);

    function ShowPatient(){
        const newData = [];
        GetDataPage('patient').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name })
            }
        })
        return newData;
    }

    function InitialData(){
        return '';
    }

    function ShowInfPatient(dataPatient){
        setIdPatient(dataPatient);
        const newData = GetDataPage('patient').find(item => item.id == dataPatient.value);
        setHealthInsurance(newData['health_insurance'])
        setPhone_1(newData['phone_1']);
        setPhone_2(newData['phone_2']);
    }

    function SaveData(event){
        event.preventDefault(); 
        props.setLoading(true);

        setTimeout(() => {
            props.setLoading(false);
            ClosePopUp();
        }, 600);
    }

    function ClosePopUp(){
        SetModalState('Schedule', false);
    }

    useEffect(()=>{
        RegisterModalData('Schedule', setModaldata);
        RegisterModalObserver('Schedule', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setId(modalData.id);
            setDate(modalData.start);
            setStartHour(InitialData('start_hour'));
            setEndHour(InitialData('end_hour'));
            setTitle(InitialData('title'));
            setIdPatient(InitialData('id_patient'));
            setHealthInsurance(InitialData('health_insurance'));
            setPhone_1(InitialData('phone_1'));
            setPhone_2(InitialData('phone_2'));
            setPrice(InitialData('price'));
            setInformation(InitialData('information'));
            setListPatient(ShowPatient());
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp" onSubmit={ SaveData }>
                <div className="all Schedule">
                    <div className="div_data type_div">
                        <div className="div_title">
                            <div className="title">
                                Agenda
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close className="icons" color="#4c4cdc" />
                            </div>
                        </div>
                    </div>
                    <div className="div_data div_content" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <Comp_Input index="not" type="text" className="" name="Nome do procedimento" setValue={ setTitle } value={ title } maxLength={ 140 } />
                            
                            <Comp_Input index="not" type="text" className="contact" name="Valor" setValue={ setPrice } value={ price } maxLength={ 20 } />
                        </div>

                        <div className="content div_Comp_select">
                            <Comp_Select index="not" type="text" className="patient" name="Paciente" opt={ listPatient } setValue={ ShowInfPatient } value={ idPatient } />

                            <Comp_Input index="not" type="text" className="contact" name="Telefone 1" setValue={ setPhone_1 } value={ phone_1 } maxLength={ 20 } />
                            
                            <Comp_Input index="not" type="text" className="contact" name="Telefone 2" setValue={ setPhone_2 } value={ phone_2 } maxLength={ 20 } />
                        </div>

                        <div className="content">
                            <Comp_Input index="not" type="text" className="" name="Nome convênio médico" setValue={ setHealthInsurance } value={ healthInsurance } maxLength={ 140 } />

                            <Comp_Input index="not" type="date" className="date" name="Data" setValue={ setDate } value={ date } maxLength={ 140 } />
                            
                            <Comp_Input index="not" type="time" className="date" name="Hora de início" setValue={ setDate } value={ startHour } maxLength={ 140 } />
                            
                            <Comp_Input index="not" type="time" className="date" name="Hora de término" setValue={ setDate } value={ endHour } maxLength={ 140 } />
                        </div>

                        <div className="content">
                            <Comp_Textarea index="not" name="Informações adicionais" setValue={ setInformation } value={ information } />
                        </div>

                        <div className="content">
                            <button type="submit" className="div_btn">Salvar</button>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
