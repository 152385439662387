// link API
export const linkApiLogin = '';

// Cookies folder project
export const cookiesRegister = './';

// Cookies name
export const typeCookie = 'wip_dashboard';
export const typeCookiePassw = 'wip_passw';
export const typeCookieEmail = 'wip_email';
export const typeCookiePage = 'wip_page';
export const typeCookiePageId = 'wip_page_id';

export const colorPrimary = '#4C4DDC';
export const colorSecondary = '#F57E16';