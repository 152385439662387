import './AddOrSave.css';

import { Svg_AddNewData, Svg_Delete, Svg_Save } from 'services/SvgFile';

export default function Comp_Btn_AddOrSave(props){
    return(
        props.type == "newData" ?
        <button className="Comp_Btn_AddOrSave" onClick={ ()=>{ props.OpenPage(props.page, 0) } }>
            <div className="div_btn">
                <Svg_AddNewData className="icons" color="#ffffff" />
            </div>
            <div className="title_btn">{ props.title }</div>
        </button>
        :
        <button className="Comp_Btn_AddOrSave">
            <div className="div_btn">
                {
                    props.type == "add" || props.type == "newData" ?
                    <Svg_AddNewData className="icons" color="#ffffff" /> :
                        props.type == "save" ?
                        <Svg_Save className="icons" color="#ffffff" /> :
                            <Svg_Delete className="icons" color="#ffffff" />

                }
            </div>
            <div className="title_btn">{ props.title }</div>
        </button>
    )
}