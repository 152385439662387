import { useState, useEffect } from "react";

import './Patient.css';

import Comp_Topic from "components/Topic";
import Comp_Btn_Search from "components/Btn/Search";
import Comp_Btn_AddOrSave from "components/Btn/AddOrSave";

import { Svg_Arrow, Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorPrimary } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Patient(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('patient'));
    const [ listData, setListData ] = useState(GetDataPage('patient'));
    
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);

    function InputSearch(value){
        const newList = [];
        if(value){
            GetDataPage('patient').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.social_name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.mother_name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.phone_1.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.health_insurance.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.card_number.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.accommodation.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.cep.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.road.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.number.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.complement.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.district.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.city.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.state.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('patient'));
        }
        setSearch(value);
    }

    function DeleteInf(id, value){
        SetModalData('Confirmation', { "origin": "patient", "type" : "delete_patient", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{        
        RegisterDataPage('patient', setShowData);
        return ()=>{
            UnRegisterDataPage('patient', setShowData);
        };
    }, []);

    return(
        <div className="show_page">
            <Comp_Topic namePage="Paciente" />

            <div className="div_show_search">
                <Comp_Btn_Search InputSearch={ InputSearch } search={ search } />

                <Comp_Btn_AddOrSave type="newData" OpenPage={ props.OpenPage } title="Adicionar novo paciente" page="patient_details" />
            </div>

            <div className="list_data_table">
                <table cellPadding={ 0 } cellSpacing={ 0 }>
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th width="60">Telefone</th>
                            <th width="60">E-mail</th>
                            <th width="60">Data de nascimento</th>
                            <th width="60">Cidade</th>
                            <th width="40" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td>{ index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td>{ elem.cpf }</td>
                                        <td>{ elem.phone_1 }</td>
                                        <td>{ elem.email }</td>
                                        <td>{ elem.date_birth }</td>
                                        <td>{ elem.city }</td>
                                        <td>
                                            <div className="div_opt">
                                                <div className="" onClick={ ()=>{ DeleteInf(elem.id, elem.name) } }>
                                                    <Svg_Delete className="icons" color={ colorPrimary } />
                                                </div>
                                                <div className="" onClick={ ()=>{ props.OpenPage('patient_details', elem.id) } }>
                                                    <Svg_Edit className="icons" color={ colorPrimary } />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 8 }>Nenhum dado registrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="inf_data">
                    <div className="">
                        Mostrando { listData.length } de { listData.length }
                    </div>
                    {
                        listData.length >= 50 ?
                            <div className="div_arrow">
                                <div className="">
                                    <Svg_Arrow className="icons" color="#A098AE" />
                                </div>
                                <div className="list_pages">
                                    {
                                        Array.from(Array(page), (item, index)=>{
                                            return(
                                                <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                                    { index + 1 }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="">
                                    <Svg_Arrow className="icons right" color="#A098AE" />
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}