import { useState } from "react";

import './Topic.css';

import { GetUserData } from "interface/Users";
import { Svg_Alert, Svg_Settings, Svg_User } from "services/SvgFile";
import { colorPrimary } from "fixedData";

export default function Comp_Topic(props){
    
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));

    return(
        <div className="Comp_Topic">
            <div className="name_page">
                { props.namePage }
            </div>
            <div className="div_config">
                <div className="div_icon">
                    <Svg_Alert className="icons" color={ colorPrimary } />
                </div>
                <div className="div_icon">
                    <Svg_Settings className="icons" color={ colorPrimary } />
                </div>
                <div className="name_user">                    
                    <div className="name_">{ userName }</div>
                    <div className="type_access">{ userAccess }</div>
                </div>
                <div className="div_icon">
                    {
                        userFile !='' ?
                        <img alt="img_user" src={ userFile } className="icons" />
                        :
                        <Svg_User className="icons" color={ colorPrimary } />
                    }
                </div>
            </div>
        </div>
    )
}