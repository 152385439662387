export function Svg_Arrow(props){
    return(
        <svg viewBox="0 0 14 21" fill="none" className={ props.className }>
            <path fill={ props.color } d="M11.512 20.676L1.04797 11.236C0.599972 10.852 0.599972 10.148 1.04797 9.76403L11.512 0.324027C12.184 -0.283973 13.272 0.164026 13.272 1.06003L13.272 19.94C13.272 20.836 12.184 21.284 11.512 20.676Z"/>
        </svg>
    )
}

export function Svg_ArrowRight(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697" stroke={ props.color } strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5 12H20.33" stroke={ props.color } strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_ChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function Svg_Erro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function Svg_Close(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Exit(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M8.90039 7.56023C9.21039 3.96023 11.0604 2.49023 15.1104 2.49023H15.2404C19.7104 2.49023 21.5004 4.28023 21.5004 8.75023V15.2702C21.5004 19.7402 19.7104 21.5302 15.2404 21.5302H15.1104C11.0904 21.5302 9.24039 20.0802 8.91039 16.5402" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0001 12H3.62012" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.85 8.65039L2.5 12.0004L5.85 15.3504" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Eye(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
        </svg>
    )
}

export function Svg_EyeClose(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" />
        </svg>
    )
}

export function Svg_User(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    )
}

export function Svg_UserDash(props){
    return(
        <svg viewBox="0 0 32 32" className={ props.className } fill="none">
            <g clipPath="url(#clip0_538_3993)">
                <path d="M10.5 20C14.0899 20 17 17.0899 17 13.5C17 9.91015 14.0899 7 10.5 7C6.91015 7 4 9.91015 4 13.5C4 17.0899 6.91015 20 10.5 20Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.27875 25.0001C2.27761 23.4643 3.64424 22.2023 5.25458 21.3286C6.86491 20.455 8.66795 19.9974 10.5 19.9974C12.332 19.9974 14.1351 20.455 15.7454 21.3286C17.3558 22.2023 18.7224 23.4643 19.7212 25.0001" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.5 20C23.332 19.9989 25.1351 20.4558 26.7456 21.329C28.356 22.2022 29.7227 23.4641 30.7213 25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.0862 7.4625C19.9754 7.10787 20.9319 6.954 21.8874 7.0119C22.8429 7.0698 23.7738 7.33802 24.6136 7.79742C25.4535 8.25681 26.1815 8.89602 26.7456 9.66939C27.3097 10.4428 27.6961 11.3312 27.8771 12.2712C28.058 13.2112 28.0291 14.1795 27.7925 15.1071C27.5558 16.0346 27.1172 16.8984 26.5079 17.6368C25.8987 18.3752 25.1339 18.9698 24.2682 19.3784C23.4025 19.7869 22.4572 19.9992 21.5 20" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_538_3993">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function Svg_Edit(props){
    return(
        <svg viewBox="0 0 24 25" fill="none" className={ props.className }>
            <path d="M11 4.2381H4C3.46957 4.2381 2.96086 4.44881 2.58579 4.82388C2.21071 5.19896 2 5.70767 2 6.2381V20.2381C2 20.7685 2.21071 21.2772 2.58579 21.6523C2.96086 22.0274 3.46957 22.2381 4 22.2381H18C18.5304 22.2381 19.0391 22.0274 19.4142 21.6523C19.7893 21.2772 20 20.7685 20 20.2381V13.2381" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.5 2.73808C18.8978 2.34026 19.4374 2.11676 20 2.11676C20.5626 2.11676 21.1022 2.34026 21.5 2.73808C21.8978 3.13591 22.1213 3.67547 22.1213 4.23808C22.1213 4.80069 21.8978 5.34026 21.5 5.73808L12 15.2381L8 16.2381L9 12.2381L18.5 2.73808Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_MenuHamburguer(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <g clipPath="url(#clip0_241_54)">
                <path d="M3.75 12H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 6H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 18H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_241_54">
                    <rect width="24" height="24" fill={ props.color }/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function Svg_Dashboard(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M9.75 3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V9.75C3.75 10.1478 3.90804 10.5294 4.18934 10.8107C4.47064 11.092 4.85218 11.25 5.25 11.25H9.75C10.1478 11.25 10.5294 11.092 10.8107 10.8107C11.092 10.5294 11.25 10.1478 11.25 9.75V5.25C11.25 4.85218 11.092 4.47064 10.8107 4.18934C10.5294 3.90804 10.1478 3.75 9.75 3.75ZM9.75 9.75H5.25V5.25H9.75V9.75ZM18.75 3.75H14.25C13.8522 3.75 13.4706 3.90804 13.1893 4.18934C12.908 4.47064 12.75 4.85218 12.75 5.25V9.75C12.75 10.1478 12.908 10.5294 13.1893 10.8107C13.4706 11.092 13.8522 11.25 14.25 11.25H18.75C19.1478 11.25 19.5294 11.092 19.8107 10.8107C20.092 10.5294 20.25 10.1478 20.25 9.75V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM18.75 9.75H14.25V5.25H18.75V9.75ZM9.75 12.75H5.25C4.85218 12.75 4.47064 12.908 4.18934 13.1893C3.90804 13.4706 3.75 13.8522 3.75 14.25V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H9.75C10.1478 20.25 10.5294 20.092 10.8107 19.8107C11.092 19.5294 11.25 19.1478 11.25 18.75V14.25C11.25 13.8522 11.092 13.4706 10.8107 13.1893C10.5294 12.908 10.1478 12.75 9.75 12.75ZM9.75 18.75H5.25V14.25H9.75V18.75ZM18.75 12.75H14.25C13.8522 12.75 13.4706 12.908 13.1893 13.1893C12.908 13.4706 12.75 13.8522 12.75 14.25V18.75C12.75 19.1478 12.908 19.5294 13.1893 19.8107C13.4706 20.092 13.8522 20.25 14.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V14.25C20.25 13.8522 20.092 13.4706 19.8107 13.1893C19.5294 12.908 19.1478 12.75 18.75 12.75ZM18.75 18.75H14.25V14.25H18.75V18.75Z"/>
        </svg>
    )
}

export function Svg_Site(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M23,11H18A1,1 0 0,0 17,12V21A1,1 0 0,0 18,22H23A1,1 0 0,0 24,21V12A1,1 0 0,0 23,11M23,20H18V13H23V20M20,2H2C0.89,2 0,2.89 0,4V16A2,2 0 0,0 2,18H9V20H7V22H15V20H13V18H15V16H2V4H20V9H22V4C22,2.89 21.1,2 20,2Z" />
        </svg>
    )
}

export function Svg_Settings(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className } stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="3"/>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
        </svg>
    )
}

export function Svg_MenuPoint(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
        </svg>
    )
}

export function Svg_Search(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={ props.className }>
            <circle cx="11" cy="11" r="8"/>
            <line x1="21" y1="21" x2="16.65" y2="16.65"/>
        </svg>
    )
}

export function Svg_AddNewData(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
        </svg>
    )
}

export function Svg_Save(props){
    return(
        <svg viewBox="0 0 32 32" className={ props.className } fill="none">
            <g clipPath="url(#clip0_280_1228)">
                <path d="M27 11.4137V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H20.5863C20.8511 5.00012 21.1051 5.10532 21.2925 5.2925L26.7075 10.7075C26.8947 10.8949 26.9999 11.1489 27 11.4137Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10 27V19C10 18.7348 10.1054 18.4804 10.2929 18.2929C10.4804 18.1054 10.7348 18 11 18H21C21.2652 18 21.5196 18.1054 21.7071 18.2929C21.8946 18.4804 22 18.7348 22 19V27" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19 9H12" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_280_1228">
                    <rect width="32" height="32" fill={ props.color }/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function Svg_Delete(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M3 6H5H21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Img(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 15L16 10L5 21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Patient(props){
    return(        
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 10C14.21 10 16 8.21 16 6S14.21 2 12 2 8 3.79 8 6 9.79 10 12 10M12 4C13.1 4 14 4.9 14 6S13.1 8 12 8 10 7.1 10 6 10.9 4 12 4M18.39 12.56C16.71 11.7 14.53 11 12 11S7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6V15.22C6 14.84 6.2 14.5 6.5 14.34C7.71 13.73 9.63 13 12 13C12.76 13 13.47 13.07 14.13 13.2L12.58 16.5H9.75C8.23 16.5 7 17.73 7 19.25S8.23 22 9.75 22H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56M10.94 20H9.75C9.34 20 9 19.66 9 19.25S9.34 18.5 9.75 18.5H11.64L10.94 20M18 20H13.15L16.09 13.73C16.63 13.93 17.1 14.14 17.5 14.34C17.8 14.5 18 14.84 18 15.22V20Z" />
        </svg>
    )
}

export function Svg_Doctor(props){
    return(        
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M14.84,16.26C17.86,16.83 20,18.29 20,20V22H4V20C4,18.29 6.14,16.83 9.16,16.26L12,21L14.84,16.26M8,8H16V10A4,4 0 0,1 12,14A4,4 0 0,1 8,10V8M8,7L8.41,2.9C8.46,2.39 8.89,2 9.41,2H14.6C15.11,2 15.54,2.39 15.59,2.9L16,7H8M12,3H11V4H10V5H11V6H12V5H13V4H12V3Z" />
        </svg>
    )
}

export function Svg_Schedule(props){
    return(        
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M21 13H3A1 1 0 0 0 2 14V20A1 1 0 0 0 3 21H21A1 1 0 0 0 22 20V14A1 1 0 0 0 21 13M20 19H4V15H20M21 3H3A1 1 0 0 0 2 4V10A1 1 0 0 0 3 11H21A1 1 0 0 0 22 10V4A1 1 0 0 0 21 3M20 9H4V5H20Z" />
        </svg>
    )
}

export function Svg_Financial(props){
    return(        
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
        </svg>
    )
}

export function Svg_Chat(props){
    return(        
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M18.72,14.76C19.07,13.91 19.26,13 19.26,12C19.26,11.28 19.15,10.59 18.96,9.95C18.31,10.1 17.63,10.18 16.92,10.18C13.86,10.18 11.15,8.67 9.5,6.34C8.61,8.5 6.91,10.26 4.77,11.22C4.73,11.47 4.73,11.74 4.73,12A7.27,7.27 0 0,0 12,19.27C13.05,19.27 14.06,19.04 14.97,18.63C15.54,19.72 15.8,20.26 15.78,20.26C14.14,20.81 12.87,21.08 12,21.08C9.58,21.08 7.27,20.13 5.57,18.42C4.53,17.38 3.76,16.11 3.33,14.73H2V10.18H3.09C3.93,6.04 7.6,2.92 12,2.92C14.4,2.92 16.71,3.87 18.42,5.58C19.69,6.84 20.54,8.45 20.89,10.18H22V14.67H22V14.69L22,14.73H21.94L18.38,18L13.08,17.4V15.73H17.91L18.72,14.76M9.27,11.77C9.57,11.77 9.86,11.89 10.07,12.11C10.28,12.32 10.4,12.61 10.4,12.91C10.4,13.21 10.28,13.5 10.07,13.71C9.86,13.92 9.57,14.04 9.27,14.04C8.64,14.04 8.13,13.54 8.13,12.91C8.13,12.28 8.64,11.77 9.27,11.77M14.72,11.77C15.35,11.77 15.85,12.28 15.85,12.91C15.85,13.54 15.35,14.04 14.72,14.04C14.09,14.04 13.58,13.54 13.58,12.91A1.14,1.14 0 0,1 14.72,11.77Z" />
        </svg>
    )
}

export function Svg_Alert(props){
    return(        
        <svg viewBox="0 0 26 32" className={ props.className } fill="none">
            <path fill={ props.color } d="M22.3677 18.9391V9.86768C22.3677 4.70215 18.1655 0.5 13 0.5C7.83447 0.5 3.63232 4.70215 3.63232 9.86768V18.9397C1.96704 19.4224 0.738281 20.9544 0.738281 22.8374C0.738281 25.0386 2.5293 26.8296 4.73096 26.8296H8.37696V26.877C8.37695 29.4263 10.4507 31.5 13 31.5C15.5493 31.5 17.6231 29.4263 17.6231 26.8769V26.8296H21.2691C23.4707 26.8296 25.2617 25.0386 25.2617 22.7583C25.2617 20.9406 24.033 19.4198 22.3677 18.9391ZM6.63232 9.86768C6.63232 6.35645 9.48877 3.5 13 3.5C16.5112 3.5 19.3677 6.35645 19.3677 9.86768V18.7661H6.63232V9.86768ZM14.6231 26.8769C14.6231 27.772 13.895 28.5 13 28.5C12.105 28.5 11.377 27.772 11.377 26.8769V26.8296H14.623V26.8769H14.6231ZM21.269 23.8296H4.73096C4.1836 23.8296 3.73828 23.3843 3.73828 22.7583C3.73828 22.2114 4.18359 21.7661 4.73096 21.7661H21.2691C21.8164 21.7661 22.2617 22.2114 22.2617 22.8374C22.2617 23.3843 21.8164 23.8296 21.269 23.8296Z" />
        </svg>
    )
}