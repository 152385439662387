import './ReturnPage.css';

import { Svg_ArrowRight } from 'services/SvgFile';

export default function Comp_Btn_ReturnPage(props){
    return(
        <div className="Comp_Btn_ReturnPage" onClick={ ()=>{ props.ReturnPage(props.page, 'remuve') } }>
            <div className="">
                <Svg_ArrowRight className="icons" color="#4C4DDC" />
            </div>
            <div className="title_btn">Voltar</div>
        </div>
    )
}