import { useState, useEffect } from "react";

import './Dashboard.css';

export default function Page_Dashboard(props){

    return(
        <div className="show_page">
        </div>
    )
}