import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Cookies from 'universal-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));

function CallbackSuccess(){
    
}

root.render(
    <React.Fragment>
        <App loadingDataPage={ CallbackSuccess } />
    </React.Fragment>
);