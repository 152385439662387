import { useState, useEffect } from "react";

import './Telemedicine.css';

import Comp_Topic from "components/Topic";
import Comp_Btn_Search from "components/Btn/Search";
import Comp_Btn_AddOrSave from "components/Btn/AddOrSave";

export default function Page_Telemedicine(props){

    const [ option, setOption ] = useState(["name"]);
    const [ listData, setListData ] = useState([]);

    function NewData(){
    }

    return(
        <div className="show_page">
            <Comp_Topic namePage="Telemedicina" />

            <div className="div_show_search">
                <Comp_Btn_Search option={ option } listData={ listData } setListData={ setListData } />

                <Comp_Btn_AddOrSave type="add" NewData={ NewData } title="Adicionar novo telemedicina" />
            </div>
        </div>
    )
}