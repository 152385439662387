import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_Chat, Svg_Dashboard, Svg_Doctor, Svg_Exit, Svg_Financial, Svg_Patient, Svg_Schedule, Svg_Settings } from 'services/SvgFile';

import { colorPrimary } from "fixedData";

import Page_Chat from "page/Chat";

import Page_Service from "page/Service";

import Page_Patient from "page/Patient";
import Page_Patient_Details from "page/Patient/Details";

import Page_Settings from "page/Settings";

import Page_Schedule from "page/Schedule";

import Page_Dashboard from "page/Dashboard";

import Page_Financial from "page/Financial";

import Page_Telemedicine from "page/Telemedicine";

export default function Contents(props){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function OpenPage(value, idPage){
        SetListPag('currentPage', value);
        SetListPag('currentPageId', idPage);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "patient":
                return <Page_Patient setLoading={ props.setLoading } OpenPage={ OpenPage } />;
            case "patient_details":
                return <Page_Patient_Details setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "schedule":
                return <Page_Schedule setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "service":
                return <Page_Service setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "financial":
                return <Page_Financial setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "chat":
                return <Page_Chat setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "telemedicine":
                return <Page_Telemedicine setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            case "settings":
                return <Page_Settings setLoading={ props.setLoading } OpenPage={ OpenPage } />;

            default: 
                return <Page_Dashboard setLoading={ props.setLoading } OpenPage={ OpenPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        // cookies.set(typeCookie, 'index', '/', cookiesRegister);
        // cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        // cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        // cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
    }, []);

    return(
        <div className="Contents">
            <div className="div_menu">
                <div className="div_logo">
                    <img alt="logo" src="./assets/logo.png" className="logo" />
                </div>
                <div className="div_opt_menu">
                    <div className={ currentPage == "index" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('index') } }>
                        <div className="icon">
                            <Svg_Dashboard className="icons" color={ currentPage == "index" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Dashboard</div>
                    </div>

                    <div className={ currentPage == "schedule" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('schedule') } }>
                        <div className="icon">
                            <Svg_Schedule className="icons" color={ currentPage == "schedule" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Agenda</div>
                    </div>

                    <div className={ currentPage == "patient" || currentPage == "patient_details" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('patient') } }>
                        <div className="icon">
                            <Svg_Patient className="icons" color={ currentPage == "patient" || currentPage == "patient_details" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Paciente</div>
                    </div>

                    <div className={ currentPage == "service" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('service') } }>
                        <div className="icon">
                            <Svg_Chat className="icons" color={ currentPage == "service" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Atendimento</div>
                    </div>

                    <div className={ currentPage == "financial" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('financial') } }>
                        <div className="icon">
                            <Svg_Financial className="icons" color={ currentPage == "financial" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Financeiro</div>
                    </div>

                    <div className={ currentPage == "chat" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('chat') } }>
                        <div className="icon">
                            <Svg_Chat className="icons" color={ currentPage == "chat" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Chat</div>
                    </div>

                    <div className={ currentPage == "telemedicine" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('telemedicine') } }>
                        <div className="icon">
                            <Svg_Doctor className="icons" color={ currentPage == "telemedicine" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Telemedicina</div>
                    </div>

                    <div className={ currentPage == "settings" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage('settings') } }>
                        <div className="icon">
                            <Svg_Settings className="icons" color={ currentPage == "settings" ? colorPrimary : "#ffffff" } />
                        </div>
                        <div className="name_page">Configuração</div>
                    </div>
                </div>
                <div className="div_exit" onClick={ ()=>{ Logout() } }>
                    <div className="">
                        <Svg_Exit className="icons" color="#FFFFFF" />
                    </div>
                    <div className="">Sair</div>
                </div>
            </div>
            <div className="div_data">
                {
                    CurrentPage()
                }
            </div>

            {/* <div className="div_notification"></div> */}
        </div>
    )
}