import { useState, useEffect } from 'react';

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from 'components/Input';
import Comp_Select from 'components/Select';
import Comp_Textarea from 'components/Textarea';
import Comp_Btn_AddOrSave from "components/Btn/AddOrSave";
import Comp_Btn_ReturnPage from "components/Btn/ReturnPage";

import { SetModalData, SetModalState } from 'interface/PopUp';
import { GetDataPage, RegisterDataPage, SetListDataSingle, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete } from 'services/SvgFile';
import { GetListPag, RegisterListPag, UnRegisterListPag } from 'interface/Page';

export default function Page_Patient_Details(props){
    
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ showData, setShowData ] = useState(GetDataPage('patient'));

    /* data patient */
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ name, setName ] = useState(InitialData('name'));
    const [ socialName, setSocialName ] = useState(InitialData('social_name'));
    const [ cpf, setCpf ] = useState(InitialData('cpf'));
    const [ dateBirth, setDateBirth ] = useState(InitialData('date_birth'));
    const [ sex, setSex ] = useState(InitialData('sex'));
    const [ patientSelect, setPatientSelect ] = useState([
        { "value": "man", "label": "Homem" },
        { "value": "woman", "label": "Mulher" },
        { "value": "other", "label": "Outro" }
    ]);
    const [ motherName, setMotherName ] = useState(InitialData('mother_name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ phone_1, setPhone_1 ] = useState(InitialData('phone_1'));
    const [ phone_2, setPhone_2 ] = useState(InitialData('phone_2'));
    const [ obs, setObs ] = useState(InitialData('obs'));
    /* end */

    /* health insurance */
    const [ healthInsurance, setHealthInsurance ] = useState(InitialData('health_insurance'));
    const [ cardNumber, setCardNumber ] = useState(InitialData('card_number'));
    const [ accommodation, setAccommodation ] = useState(InitialData('accommodation'));
    const [ healthSelect, setHealthSelect ] = useState([
        { "value": "semi_private", "label": "Semi-privado" },
        { "value": "private", "label": "Privativo" }
    ]);
    /* end */

    /* address */
    const [ road, setRoad ] = useState(InitialData('road'));
    const [ number, setNumber ] = useState(InitialData('number'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ district, setDistrict ] = useState(InitialData('district'));
    const [ state, setState ] = useState(InitialData('state'));
    const [ complement, setComplement ] = useState(InitialData('complement'));
    /* end */

    /* list contact */
    const [ listContact, setListContact ] = useState(InitialData('contact'));
    const [ kinshipSelect, setKinshipSelect ] = useState([
        { "value": "father", "label": "Pai" },
        { "value": "mother", "label": "Mãe" },
        { "value": "husband", "label": "Marido" },
        { "value": "wife", "label": "Esposa" },
        { "value": "son", "label": "Filho" },
        { "value": "daughter", "label": "Filha" },
        { "value": "uncle", "label": "Tio" },
        { "value": "aunt", "label": "Tia" },
        { "value": "cousin", "label": "Primo(a)" },
        { "value": "other", "label": "Outros" }
    ]);
    /* end */

    /* contact */
    function HandleData(type, index, value){
        const newData = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }
    function DeleteContact(id, index, value){
        if(id == 0){
            const newData = [...listContact];
            newData.splice(index, 1);
            setListContact(newData);
        }else {                
            SetModalData('Confirmation', { "origin": "patient", "type" : "delete_contact", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function InitialData(type){
        const newData = GetDataPage('patient').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        if(type === "contact"){
            return [];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);

        setTimeout(() => {
            props.setLoading(false);
        }, 600);
    }

    useEffect(()=>{        
        RegisterDataPage('patient', setShowData);
        RegisterListPag('currentPageId', setIdPage);
        return ()=>{
            UnRegisterDataPage('patient', setShowData);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    return(
        <form className="Page_Patient_Details show_page" onSubmit={ SaveData }>
            <Comp_Topic namePage="Informações do paciente" />
            
            <div className="div_show_search">
                <Comp_Btn_ReturnPage ReturnPage={ props.OpenPage } page="patient" />

                <Comp_Btn_AddOrSave type="save" OpenPage={ ()=>{ } } title="Salvar dados" page="patient_details" />
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Dados do paciente</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="" name="Nome" setValue={ setName } value={ name } maxLength={ 140 } />

                    <Comp_Input index="not" type="text" className="" name="Nome social" setValue={ setSocialName } value={ socialName } maxLength={ 140 } />

                    <Comp_Select index="not" type="text" className="sex" name="Sexo" opt={ patientSelect } setValue={ setSex } value={ sex } />
                </div>

                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="patient_cpf" name="CPF" setValue={ setCpf } value={ cpf } maxLength={ 14 } />

                    <Comp_Input index="not" type="date" className="patient_date_birth" name="Data de nascimento" setValue={ setDateBirth } value={ dateBirth } maxLength={ 140 } />

                    <Comp_Input index="not" type="text" className="" name="E-mail" setValue={ setEmail } value={ email } maxLength={ 60 } />

                    <Comp_Input index="not" type="text" className="patient_contact" name="Telefone 1" setValue={ setPhone_1 } value={ phone_1 } maxLength={ 20 } />

                    <Comp_Input index="not" type="text" className="patient_contact" name="Telefone 2" setValue={ setPhone_2 } value={ phone_2 } maxLength={ 20 } />
                </div>

                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="" name="Nome da mãe" setValue={ setMotherName } value={ motherName } maxLength={ 140 } />
                </div>
                    
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Observação" setValue={ setObs } value={ obs } />
                </div>
            </div>            

            <div className="show_data_page">
                <div className="div_opt_data">Convênio médico</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="health_insurance" name="Nome" setValue={ setHealthInsurance } value={ healthInsurance } maxLength={ 140 } />
                    
                    <Comp_Input index="not" type="text" className="health_insurance" name="Número da carteirinha" setValue={ setCardNumber } value={ cardNumber } maxLength={ 60 } />

                    <Comp_Select index="not" type="text" className="accommodation" name="Acomodação" opt={ healthSelect } setValue={ setAccommodation } value={ accommodation } />
                </div>
            </div>            

            <div className="show_data_page">
                <div className="div_opt_data">Endereço</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="" name="Rua" setValue={ setRoad } value={ road } maxLength={ 140 } />

                    <Comp_Input index="not" type="text" className="address_number" name="Número" setValue={ setNumber } value={ number } maxLength={ 6 } />

                    <Comp_Input index="not" type="text" className="address_cep" name="CEP" setValue={ setCep } value={ cep } maxLength={ 140 } />
                </div>
                    
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" className="" name="Cidade" setValue={ setCity } value={ city } maxLength={ 140 } />

                    <Comp_Input index="not" type="text" className="" name="Bairro" setValue={ setDistrict } value={ district } maxLength={ 140 } />
                    
                    <Comp_Input index="not" type="text" className="address_uf" name="UF" setValue={ setState } value={ state } maxLength={ 2 } />
                </div>
                    
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Complemento" setValue={ setComplement } value={ complement } />
                </div>
            </div>            

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Dados de contato</div>
                    <div className="" onClick={ ()=>{ setListContact([...listContact, { "id": 0, "name": "", "cpf": "", "email": "", "number": "", "kinship": "" }]); } }>
                        Adicionar novo contato
                    </div>
                </div>
                {
                    listContact.length > 0 ?
                        listContact.map((elem, index)=>{
                            return(
                                <div className="show_content_data div_border" key={ index }>
                                    <Comp_Input index={ index } type="text" className="" name="Nome" input="name" setValue={ HandleData } value={ elem.name } maxLength={ 140 } />

                                    <Comp_Input index={ index } type="text" className="" name="E-mail" input="email" setValue={ HandleData } value={ elem.email } maxLength={ 140 } />

                                    <Comp_Input index={ index } type="text" className="patient_contact" name="Número" input="number" setValue={ HandleData } value={ elem.number } maxLength={ 20 } />

                                    <Comp_Input index={ index } type="text" className="patient_cpf" name="CPF" input="cpf" setValue={ HandleData } value={ elem.cpf } maxLength={ 140 } />

                                    <Comp_Select index={ index } type="text" className="" name="Parentesco" input="kinship" opt={ kinshipSelect } setValue={ HandleData } value={ elem.kinship } />

                                    <div className="div_delete" onClick={ ()=>{ DeleteContact(elem.id, index, elem.name) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :                    
                    <div className="show_content_data">
                        <div className="no_data">Nenhum dado encontrado...</div>
                    </div>
                }
            </div>
        </form>
    )
}