import { useState } from 'react';

import './Search.css';

import { Svg_Close, Svg_Search } from "services/SvgFile";

import { colorPrimary } from "fixedData";

export default function Comp_Btn_Search(props){

    const [ status, setStatus ] = useState('');

    return(
        <div className="Comp_Btn_Search">
            {
                status !='' ?
                <div className="div_icons" onClick={ ()=>{ props.InputSearch(''); setStatus('') } }>
                    <Svg_Close className="icons" color={ colorPrimary } />
                </div>
                :
                <div className="div_icons">
                    <Svg_Search className="icons" color={ colorPrimary } />
                </div>
            }
            <input type="text" className="" onChange={ (e)=>{ props.InputSearch(e.target.value); setStatus(e.target.value) } } placeholder="digite sua pesquisa..." value={ props.search } />
        </div>
    )
}