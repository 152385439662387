
let DataPage = {
    "patient" : [
        {
            "id": 1,
            "file": "",
            "name": "Teste 1",
            "social_name": "",
            "cpf": "",
            "date_birth": "",
            "sex": "",
            "mother_name": "",
            "email": "teste1@teste.com",
            "phone_1": "11111111111",
            "phone_2": "22222222222",
            "obs": "",
            "health_insurance": "Teste 1111 aaa",
            "card_number": "",
            "accommodation": "",
            "cep": "",
            "road": "",
            "number": "",
            "complement": "",
            "district": "",
            "city": "",
            "state": "",
            "contact": [
            ]
        },
        {
            "id": 2,
            "file": "",
            "name": "Teste 2",
            "social_name": "",
            "cpf": "",
            "date_birth": "",
            "sex": "Masculino",
            "mother_name": "",
            "email": "teste2@teste.com",
            "phone_1": "33333333333",
            "phone_2": "44444444444",
            "obs": "",
            "health_insurance": "Teste 2222 aaa",
            "card_number": "",
            "accommodation": "",
            "cep": "",
            "road": "",
            "number": "",
            "complement": "",
            "district": "",
            "city": "",
            "state": "",
            "contact": [
            ]
        }
    ],
    "schedule": [
        {
            "id": 1,
            "start": "2024-06-27 12:00:00",
            "start_br": "27/06/2024",
            "end": "2024-06-27 12:00:00",
            "colorEvento": "#0000ff",
            "color": "#ffffff",
            "start_hour": "09:00:00",
            "end_hour": "11:00:00",
            "end_hour": "11:00:00",
            "title": "Teste 123",
            "id_patient": {
                "value": 1,
                "label": "Teste 1"
            },
            "health_insurance": "Teste Aaaa",
            "phone_1": "111111",
            "phone_2": "111111",
            "price": "111111",
            "information": "111111"
        }
    ]
};

let NotifyDataPage = {
    "patient" : [],
    "schedule" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["patient"].forEach(element => { element(value['patient']); });
    NotifyDataPage["schedule"].forEach(element => { element(value['schedule']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}