import { useState, useEffect } from "react";

import { GetListPag, RegisterListPag } from "interface/Page";

import Login from "templates/Login";
import Contents from "templates/Contents";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_Schedule from "components/PopUp/Schedule";
import PopUP_ShowFile from "components/PopUp/ShowFile";

export default function App(props) {

    const [ loading, setLoading ] = useState(false); 
    const [ typePage, setTypePage ] = useState(GetListPag('page'));

    function CheckedPage(){
        switch (typePage) {
            case 'dashboard':
                return <Contents setLoading={ setLoading } />;
        
            default:
                return <Login setLoading={ setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
    }, []);
    
    return(
        <>
            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            {
                CheckedPage()
            }

            <PopUP_Confirmation setLoading={ setLoading } />            
            <PopUP_Schedule setLoading={ setLoading } />
            <PopUP_ShowFile setLoading={ setLoading } />
        </>
    )
}