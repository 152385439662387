import { useState, useEffect } from "react";

import './Schedule.css';

import Comp_Topic from "components/Topic";
import Comp_Btn_Search from "components/Btn/Search";
import Comp_Btn_AddOrSave from "components/Btn/AddOrSave";

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "moment/locale/pt-br";

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Schedule(props){

    const localizer = momentLocalizer(moment);

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('schedule'));
    const [ listData, setListData ] = useState(GetDataPage('schedule'));

    const dateAtual = new Date();
    const views = { month: true, week: true, day: true, agenda: false }
    const defaultMessages = {
        date: "Data",
        time: "Hora",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Eventos",
        day: "Dia",
        month: "Mês",
        previous: "Anterior",
        next: "Próximo",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",
        noEventsInRange: "Não há tarefas nesse período.",
        showMore: function showMore(total) {
            return "+" + total + " tarefas";
        }
    };

    function InputSearch(value){        
    }

    function NewData(){
        SetModalData('Schedule', { "id": 0, "start": "", "start_br": "" });
        SetModalState('Schedule', true);
    }
    
    function NewSchedule(elem){
        let newDateYear = elem.start.getUTCFullYear();
        let newDateMonth = elem.start.getUTCMonth() + 1;
        newDateMonth = newDateMonth > 9 ? newDateMonth : "0" + newDateMonth;
        let newDateDay = elem.start.getUTCDate();
        newDateDay = newDateDay > 9 ? newDateDay : "0" + newDateDay;
        const newDate = newDateYear + '-' + newDateMonth  + '-' + newDateDay;
        const newDateBr = newDateDay + '/' + newDateMonth  + '/' + newDateYear;

        console.log(newDate, newDateBr);

        SetModalData('Schedule', { "id": 0, "start": newDate, "start_br": newDateBr });
        SetModalState('Schedule', true);
    }

    function DetailsSchedule(elem){
        console.log(elem);
        SetModalData('Schedule', { "id": 0, "start": "", "start_br": "" });
        SetModalState('Schedule', true);
    }

    return(
        <div className="Page_Schedule show_page">
            <Comp_Topic namePage="Agenda" />

            <div className="div_show_search">
                {/* <Comp_Btn_Search InputSearch={ InputSearch } search={ search } /> */}
                <div className="" />

                <Comp_Btn_AddOrSave type="newData" OpenPage={ NewData } title="Adicionar novo agendamento" />
            </div>

            <div className="show_data_page div_calendar">
                <Calendar
                    views={ views }
                    messages={ defaultMessages }
                    localizer={ localizer }
                    events={ listData }
                    eventPropGetter={(listData) => {
                        const backgroundColor = listData.colorEvento ? listData.colorEvento : 'blue';
                        const color = listData.color ? listData.color : 'blue';
                        return { style: { backgroundColor, color} }
                    }}
                    
                    selectable={ true }
                    onSelectSlot={ NewSchedule }
                    onSelectEvent={ DetailsSchedule }
                />
            </div>
        </div>
    )
}