import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_AltStatus } from "services/Register";

export default function PopUP_Confirmation(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function TypeAlt(){
        ClosePopUp();
        // props.setLoading(true);
        // Reg_AltStatus(userId, modalData.origin, modalData.type, modalData.id, ()=>{ props.CallbackSuccess(); ClosePopUp() }, ()=>{ props.CallbackError(); ClosePopUp() });
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            default:
                return "Deletar";
        }
    }

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all Confirmation">
                    <div className="div_data" style={ { paddingBottom: '10px' } }>
                        <div className="div_title">
                            <div className="title">
                                { TypeRemoval() }
                            </div>
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: '6px' } }>
                        <div style={ { paddingBottom: '20px' } }>{ modalData.name }</div>
                        <div className="content div_confirmation">
                            <div onClick={ ()=>{ TypeAlt(); } }>
                                <div className="opt_select yes_update">Sim</div>
                            </div>
                            <div onClick={ ()=>{ ClosePopUp(); } }>
                                <div className="opt_select not_update">Não</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
