import './Select.css';

import Select from 'react-select';

export default function Comp_Select(props){
    return(
        <div className="Comp_Select">
            <Select className={ props.className } defaultOptions options={ props.opt } onChange={ (e)=> { props.index === "not" ? props.setValue(e) : props.setValue(props.input, props.index, e) } } value={ props.value } placeholder="..." />
            <div className="name">{ props.name }</div>
        </div>
    )
}